export default {
    location: "mdi-map-marker",
    osWindows: "mdi-microsoft-windows",
    windows: "mdi-microsoft-windows",
    "windows phone": "mdi-microsoft-windows",
    osMac: "mdi-apple",
    cpu: "mdi-memory",
    ram: "mdi-chip",
    architecture64: "mdi-cpu-64-bit",
    architecture32: "mdi-cpu-32-bit",
    displays: "mdi-monitor",
    resolution: "mdi-monitor",
    headset: "mdi-headset",
    webcam: "mdi-webcam",
    downloadSpeed: "mdi-download-network",
    uploadSpeed: "mdi-upload-network",
    latency: "mdi-server-network",
    jitter: "mdi-play-network",
    warning: "mdi-alert",
    intro: "mdi-star-box-outline",
    user: "mdi-account",
    computer: "mdi-desktop-classic",
    devices: "mdi-usb",
    internet: "mdi-wan",
    results: "mdi-clipboard-text",
    questions: "mdi-help",
    sign: "mdi-signature-freehand",
    mobile: "mdi-cellphone",
    tablet: "mdi-tablet",
    android: "mdi-cellphone",
    ios: "mdi-apple-ios",
    application: "mdi-application",
    blackberry: "mdi-cellphone",
    'google.com': "mdi-google",
    'facebook.com': "mdi-facebook",
    'email': "mdi-email",
}