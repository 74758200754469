import firebase from 'firebase'
import "firebase/auth";
import "firebase/firestore";


const firebaseConfig = {
    apiKey: "AIzaSyBsS2eJF0KPfI4ol_3iF3HqGj4XDTTyZxg",
    authDomain: "verify.vipdesk.com",
    databaseURL: "https://verify-67222.firebaseio.com",
    projectId: "verify-67222",
    storageBucket: "verify-67222.appspot.com",
    messagingSenderId: "496162285802",
    appId: "1:496162285802:web:dc128887ba257b2efecf93",
    measurementId: "G-8ZYV971GCG"
  };
  
  firebase.initializeApp(firebaseConfig);
  

export default firebase