import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: '#173042',
            secondary: '#7CAD3E',
            accent: '#7CAD3E',
            error: '#b71c1c',
          },
        },
      },
});
