import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
// import isSupported from '../utils/isSupported'
import store from '../store' // ...or wherever your `vuex` store is defined
import device from '../utils/device'
let browserVersion  = +((device.browser.version || '0.0').split('.')[0] || '0')
console.log({browserVersion})
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }, {
    path: '/test/:step?/:retry?',
    name: 'Test',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Test" */ '../views/Test.vue')
  }, {
    path: '/unsupported/:reason?',
    name: 'Unsupported',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Unsupported" */ '../views/Unsupported.vue')
  }, {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue')
  }, {
    path: '/mobile',
    name: 'Mobile',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Mobile" */ '../views/Mobile.vue')
  }, {
    path: '/apply',
    name: 'Apply',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Apply" */ '../views/Apply.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// const supportedBrowser = ['Chrome', 'Microsoft Edge']
// const supportedBrowser = [{
//   name: 'Chrome', version: 85 },{name:'Microsoft Edge', version: 85 }]

const supportedBrowser = {
  'Chrome': 85,
  'Microsoft Edge': 85,
}

let supportedBrowserName = Object.keys(supportedBrowser)

// let init = false
router.beforeEach(async (to, from, next) => {
  await store.restoredhttp
  // console.log(to.name,device.platform.type === 'desktop', supportedBrowserName.indexOf(device.browser.name) > -1,  +device.browser.version.split('.')[0], +device.browser.version.split('.')[0] >= supportedBrowser[device.browser.name])
  switch (to.name) {
    case 'Unsupported':
      if (device.platform.type === 'desktop' && supportedBrowserName.indexOf(device.browser.name) > -1 && browserVersion >= supportedBrowser[device.browser.name]){
        return next({ name: 'Home' })
      }
      return next()
    case 'Mobile':
      if (device.platform.type === 'desktop') {
        return next({ name: 'Home' })
      } else if (!store.state.user) {
        return next({ name: 'Home' })
      }
      return next()
    case 'Login':
      if (store.state.user) {
        return next({ name: 'Test' })
      }
      return next()
    case 'Test':
      if (!store.state.user) {
        //&& to.params.step !== 'intro' && to.params.step !== 'login'
        // to.params.step = 'intro'
        // store.commit('SET_STEP', to.fullPath)
        // console.log('NEXT')
        return next({ name: 'Home' })
      }
      else if (device.platform.type === 'desktop' && supportedBrowserName.indexOf(device.browser.name) < 0) {
        return next({ name: 'Unsupported', params: { reason: 'browser' } })
      }
      if (device.platform.type === 'desktop' && supportedBrowserName.indexOf(device.browser.name) > -1 && browserVersion < supportedBrowser[device.browser.name]) {
        return next({ name: 'Unsupported', params: { reason: 'browserVersion' } })
      }
      else if (!to.params.step) {
        to.params.step = 'intro'
        store.commit('SET_STEP', to.fullPath)
        return next(to)
      }
      store.commit('SET_STEP', to.fullPath)
      return next()
    default:
      console.log(store.state.session.currentStep)
      if (device.os.name === 'Chrome OS') {
        return next({ name: 'Unsupported', params: { reason: 'os' } })
      }
      // if (device.platform.type !== 'desktop') {
      //   return next({ name: 'Mobile' })
      // }
      if (device.platform.type === 'desktop' && supportedBrowserName.indexOf(device.browser.name) < 0) {
        return next({ name: 'Unsupported', params: { reason: 'browser' } })
      }

      if (device.platform.type === 'desktop' && supportedBrowserName.indexOf(device.browser.name) > -1 && browserVersion < supportedBrowser[device.browser.name]) {
        return next({ name: 'Unsupported', params: { reason: 'browserVersion' } })
      }

      if (store.state.session.currentStep) {
        return next(store.state.session.currentStep)
      }
      return next()
  }

  // // if (from.name == 'Login' && store.state.user) {
  // //   console.log('LOGIN',to, from)
  // //   to.params.step = 'intro'
  // //   return next('/test/intro')
  // // } else  
  // // if (!init) {
  // //   console.log('INIT',store.state.session)
  // //   init = true
  // //   if (store.getters.testSummary.complete === 1) {
  // //     console.log('RESULTS')
  // //     return next('/test/results')
  // //   } else if (store.state.session.currentStep) {
  // //     console.log('LAST STEP')
  // //     return next({path:store.state.session.currentStep})
  // //   } else if (!from.name) {
  // //     console.log('HOME')
  // //     return next({ name: 'Home' })
  // //   } 
  // // } else 
  // else if (to.name == 'Test') {
  //   console.log('ELSE')
  //   if (!isSupported.overall) {
  //     let reason = isSupported.reason
  //     return next({ name: 'Unsupported', params: { reason } })
  //   } else if (!to.params.step) {
  //     to.params.step = 'intro'
  //     return next(to)
  //   } else {
  //     if (to.params.step !== 'intro'){
  //       store.commit('SET_STEP', to.fullPath)
  //     }
  //     console.log('GO TO STEP',to, to.name)
  //     next()
  //   }
  // } else if (to.name === 'Unsupported' && isSupported.overall) {
  //   return next({ name: 'Home' })
  // }  else {
  //   console.log('LAST NEXT')
  //   next()
  // }

})

export default router
