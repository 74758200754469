import firebase from "./firebase"
require("firebase/firestore")
const db = firebase.firestore()
import store from "../store"

const saveDoc = async (id, doc, collection) => {
    if (!doc) { return }
    let data = purgeUndefined(doc)
    data.uid = store.state.user.uid
    await db.collection(collection)
        .doc(id || undefined).set(data, { merge: true })
        .then(result => {
            return result
        })
        .catch((error) => {
            return { error }
        })
    return null
}

function purgeUndefined(obj) {
    let payload = JSON.parse(JSON.stringify(obj))
    let keys = Object.keys(payload)
    if (!keys || !keys.length) {
        return payload
    }
    Object.keys(payload).forEach(key => {
        if (payload[key] === null) {
            payload[key] = null
        } else if (payload[key] == undefined) {
            payload[key] = null
        } else if (typeof payload[key] === 'object') {
            payload[key] = purgeUndefined(payload[key])
        }
    })
    return payload
}

export default saveDoc
