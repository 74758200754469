
let config = {
    requirements: {
        location: {
            US: ["CO",
                "FL",
                "GA",
                "IL",
                "IN",
                "MI",
                "MD",
                "ME",
                "NV",
                "NM",
                "NJ",
                "NC",
                "OH",
                "TN",
                "TX",
                "UT",
                "VA",
                "WI",
                "NY"],
            CA: [
                "ON"
            ],
            help: ''
        },
        os: {
            Windows: 10, // min version
            macOs: 10,
            help:''
        },
        displays: {
            count: 2, // min version
            exempt: true,
            resolution: [1920, 1028],
            help: ''
        },
        internet: {
            download: 25,
            upload: 10,
            jitter: 50,
            latency: 50,
            help: ''
        },
        computer: {
            architecture: [64],
            cpu: ['2,2'],
            ram: 4,
            excludeCPUs: '/(Celeron)/',
            help: ''
        },
        devices: {
            webcam: true,
            headset: true,
            help: ''
        }
    }
}

const checkCPU = (value, config) => {
    let result = {
        test: 'computer',
        config,
        pass: false,
        exempt: false,
        missing: !value || !value.cpu,
        value: {
            cpu: value ? value.cpu : null,
            cpuCores: value ? value.cpuCores : null,
            cpuSpeed: value ? value.cpuSpeed : null,
            cpuProcessors: value ? value.cpuProcessors : null,
            cpuBrand: value ? value.cpuBrand : null,
        },
        title: 'CPU',
        display: value ? `${value.cpuSpeed}GHz x ${value.cpuCores} - ${value.cpu}` : null,
        icon: 'mdi-memory',
        messages: []
    }
    console.log('checkCPU',{value, config})
    if (!value) {
        result.messages.push('Unfortunately, we were not able to detect your computer details.')
        return result
    }
    // if (value.cpu.match(config.cpu)) {
    //     result.messages.push('Unfortunately, your CPU does not meet our minimim requirements.')
    //     return result
    // }
    console.log(config.cpu,'config.cpu')

    config.cpu.forEach(cpu => {
        let parts = cpu.split(',')
        let speed = parts[0]
        let cores = parts[1]
        console.log({ speed, cores, value })
        if (value.cpuSpeed >= speed && value.cpuCores >= cores) {
            result.pass = true
        }
    })
    if (!result.pass) {
        result.messages.push('Unfortunately, your CPU does not meet our minimim requirements.')
    }
    return result
}

const checkRAM = (value, config) => {
    let result = {
        test: 'computer',
        missing: !value,
        config,
        pass: false,
        exempt: false,
        value: value || null,
        title: 'RAM',
        display: value ? `${value}GB` : null,
        icon: 'mdi-chip',
        messages: []
    }
    if (!value) {
        result.messages.push('Unfortunately, we were not able to detect your RAM.')
        return result
    } else if (value >= config.ram) {
        result.pass = true
    } else {
        result.messages.push('Unfortunately, your RAM does not meet our minimim requirements.')

    }
    return result
}

const checkOS = (value, config) => {
    let result = {
        test: 'computer',
        config,
        pass: false,
        exempt: false,
        missing: !value || !value.os,
        value: {
            os: value?.os || null,
            osName: value?.osName || null,
            osVersion: value?.osVersion || null,
        },
        title: 'OS',
        display: value?.osName || null,
        icon: value?.os === 'Windows' ? 'mdi-microsoft-windows' : value?.os === 'macOs' ? 'mdi-apple' : 'mdi-alert',
        messages: []
    }
    if (!value) {
        result.messages.push('Unfortunately, we were not able to detect your Operating System.')
        return result
    } else if (config[value.os]) {
        if (value.osVersion >= config[value.os]) {
            result.pass = true
        } else {
            result.messages.push('Unfortunately, your Operating System version is not supported.')
        }
    } else {
        result.messages.push('Unfortunately, your Operating System is not supported.')

    }
    return result
}

const checkDownload = (value, config) => {
    let result = {
        test: 'internet',
        config,
        pass: false,
        exempt: false,
        missing: !value,
        value: value ? value.value : null,
        title: 'Download',
        display: value ? `${value.value}MB/s` : null,
        icon: 'mdi-download-network',
        messages: []
    }
    if (!value) {
        result.messages.push('Unfortunately, we were not able to determin your download speed.')
        return result
    } else if (result.value >= config.download) {
        result.pass = true
    } else {
        result.messages.push('Unfortunately, your Internet Download speed is too slow.')
    }
    return result
}

const checkUpload = (value, config) => {
    let result = {
        test: 'internet',
        config,
        pass: false,
        exempt: false,
        missing: !value,
        value: value ? value.value : null,
        title: 'Upload',
        display: value ? `${value.value}MB/s` : null,
        icon: 'mdi-upload-network',
        messages: []
    }
    if (!value) {
        result.messages.push('Unfortunately, we were not able to determin your upload speed.')
        return result
    } else if (result.value >= config.upload) {
        result.pass = true
    } else {
        result.messages.push('Unfortunately, your Internet upload speed is too slow.')
    }
    return result
}


const checkLatency = (value, config) => {
    let result = {
        test: 'internet',
        config,
        pass: false,
        exempt: false,
        missing: !value,
        value: value ? value.value : null,
        title: 'Latency',
        display: value ? `${value.value}ms` : null,
        icon: 'mdi-server-network',
        messages: []
    }
    if (!value) {
        result.messages.push('Unfortunately, we were not able to determin your internet latency.')
        return result
    } else if (result.value <= config.latency) {
        result.pass = true
    } else {
        result.messages.push('Unfortunately, your Internet latency is too high.')
    }
    return result
}


const checkHeadset = (value, config) => {
    let devices = value ? Object.keys(value).map(key => value[key]) : []
    let headsets = devices.filter(device => device.headset)
    let items = []
    headsets.forEach(headset => items.push(headset.items))
    let result = {
        test: 'devices',
        config,
        pass: false,
        exempt: false,
        missing: !value,
        value: items.reduce((obj,item)=>{
            obj[item.id] = item
            return item
        },{}),
        title: 'Headset',
        display: headsets.map(device => device.name).join(', '),
        icon: 'mdi-headset',
        messages: []
    }
    if (!value || !headsets.length) {
        result.messages.push('Unfortunately, we were not able to determine your headset')
        return result
    } else if (headsets.length) {
        result.pass = true
    }
    return result
}

const checkWebcam = (value, config) => {
    console.log('checkWebcam',{ value })
    let devices = value ? Object.keys(value).map(key => value[key]) : []
    let webcams = devices.filter(device => device.webcam)
    let items = []
    webcams.forEach(webcam => items.push(webcam.items))
    let result = {
        test: 'devices',
        config,
        pass: false,
        exempt: true,
        missing: !value,
        value: items.reduce((obj,item)=>{
            obj[item.id] = item
            return item
        },{}),
        title: 'Webcam',
        display: webcams.map(device => device.name).join(', '),
        icon: 'mdi-webcam',
        messages: []
    }

    if (!value || !webcams.length) {
        result.messages.push('Unfortunately, we were not able to determine your webcam')
        return result
    } else if (webcams.length) {
        result.pass = true
    }

    return result
}

const checkJitter = (value, config) => {
    let result = {
        test: 'internet',
        config,
        pass: false,
        exempt: false,
        missing: !value,
        value: value ? value.value : null,
        title: 'Jitter',
        display: value ? `${value.value}ms` : null,
        icon: 'mdi-play-network',
        messages: []
    }
    if (!value) {
        result.messages.push('Unfortunately, we were not able to determin your internet jitter.')
        return result
    } else if (result.value <= config.jitter) {
        result.pass = true
    } else {
        result.messages.push('Unfortunately, your Internet jitter is too high.')
    }
    return result
}

const checkArchitecture = (value, config) => {
    console.log({ value, config })
    let result = {
        test: 'computer',
        config,
        pass: false,
        missing: !value,
        exempt: false,
        value: value || null,
        title: 'Architecture',
        display: value ? `${value}bit` : null,
        icon: value == 64 ? 'mdi-cpu-64-bit' : 'mdi-cpu-32-bit',
        messages: []
    }
    if (!value) {
        result.messages.push('Unfortunately, we were not able to determine your systems architecture.')
        return result
    } else if (config.architecture.indexOf(value) > -1) {
        result.pass = true
    } else {
        result.messages.push(`Unfortunately, your system's architecture does not meet our requirements.`)
    }
    console.log({result})
    return result
}

const checkLocation = (value, config) => {
    console.log('l', { config })

    let result = {
        test: 'location',
        config,
        pass: false,
        exempt: false,
        value: value || null,
        title: 'Location',
        missing: !value || !value.city,
        display: value ? `${value.city}, ${value.st}. ${value.co}` : null,
        icon: 'mdi-map-marker',
        messages: []
    }
    if (!value) {
        result.messages.push('Unfortunately, we were not able to detect your location')
        return result
    }
    if (config[value.co]) {
        if (config[value.co].indexOf(value.st) > -1) {
            result.pass = true
        } else {
            result.messages.push('Unfortunately, we are not currently hiring in your state/provence.')
        }
    } else {
        result.messages.push('Unfortunately, we are not currently hiring in your country.')
    }
    return result
}

// const checkDisplayCount = (value,config)=>{
//     console.log('c',{config})
//     let displays = Object.keys(value || {}).length
//     let result = {
//         config,
//         pass: false,
//         value: displays,
//         title: 'Monitors',
//         display: `${displays} monitor${displays == 1 ? '' : 's'}`,
//         icon: 'mdi-monitor',
//         errors: []
//     }
//     if (result.value >= result.config.count.requirement){
//             result.pass = true
//     } 
//     return result
// }


const checkDisplays = (value, config) => {
    let displays = value ? Object.keys(value).map(key => value[key]) : []
    let result = {
        config,
        pass: false,
        exempt: true,
        test: 'displays',
        missing: !value || !displays,
        value: value ? displays : null,
        title: 'Monitors',
        display: displays.map(display => `${display.width} x ${display.height}`).join(', '),
        icon: 'mdi-monitor',
        messages: []
    }
    if (!value) {
        result.messages.push('Unfortunately, we were not able to detect your monitor(s).')
        return result
    }
    if (displays && displays.length >= config.count) {
        result.value.forEach(display => {
            if (display.width >= config.resolution[0] && display.height >= config.resolution[1]) {
                result.pass = true
            }
        })
        if (!result.pass) {
            result.messages.push('Unfortunately your monitors do not meet our minimum requirements for resoltion')
        }
    } else {
        result.count = displays.length
        if (config.exempt && displays && displays.length>0){
            result.messages.push(`We were able to detect ${displays.length} monitor${displays.length==1?'':'s'}. Please review the requirements for your position to ensure you meet requirements`)
        } else {
            result.messages.push('Unfortunately we require more monitors than what we were able to detect.' )
        }
    }
    return result
}

const getSummary = (state) => {
    // results: {
    //     device,
    //     location: null,
    //     computer: null,
    //     internet: null,
    //     devices: null,
    //     displays: null,
    //   }
    // console.log(state)
    let { results } = state
    let summary = {
        pass: true,
        complete: false,
        missing: [],
        fails: [],
        device: results.device,
        checks: {
            location: checkLocation(results.location, config.requirements.location),
            os: checkOS(results.computer, config.requirements.os),
            cpu: checkCPU(results.computer, config.requirements.computer),
            ram: checkRAM(results?.computer?.ram, config.requirements.computer),
            architecture: checkArchitecture(results?.computer?.architecture, config.requirements.computer),
            headset: checkHeadset(results?.devices, config.requirements.devices),
            webcam: checkWebcam(results?.devices, config.requirements.devices),
            displays: checkDisplays(results.displays, config.requirements.displays),
            download: checkDownload(results?.internet?.download, config.requirements.internet),
            upload: checkUpload(results?.internet?.upload, config.requirements.internet),
            latency: checkLatency(results?.internet?.latency, config.requirements.internet),
            jitter: checkJitter(results?.internet?.jitter, config.requirements.internet),
        }
    }
    let missingCount = 0
    Object.keys(summary.checks).map(key => {
        let check = summary.checks[key]
        if (check.missing) {
            summary.missing.push(key)
            if (!check.exempt) {
                missingCount++
                summary.pass = false
            }
        } else if (!check.pass && !check.exempt) {
            summary.pass = false
            summary.fails.push(key)
        }
    })
    if (!missingCount ){
        summary.complete = true
    }
    return summary
}

export default getSummary