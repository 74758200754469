import Bowser from "bowser"
import UAParser from "ua-parser-js"
const parser = new UAParser();
const results = parser.getResult()

let device = Bowser.parse(window.navigator.userAgent)
device.device = {
    vendor: results.device.vendor || null,
    model: results.device.model || null,
    type: results.device.type || null,
}
device.userAgent = window.navigator.userAgent
console.log(device)
export default device