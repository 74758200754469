<template>
  <div class="text-center">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on">
          <v-avatar size="24">
            <img :src="user.photoURL" :alt="user.displayName" />
          </v-avatar>
          <span>
            {{ user.displayName }}
          </span>
        </v-btn>
      </template>
      <v-list>
        <v-list-item @click="logout">
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item>
        <v-list-item  @click="reset" v-if="device.platform.type === 'desktop'">
          <v-list-item-title>Restart Verify</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapState } from "vuex";
import firebase from "../utils/firebase";
import device from "../utils/device";

export default {
  data(){
    return {
      device
    }
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    logout() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$store.commit("RESET");
          location.reload()
        //   this.$router.push("/").then(() => {
        //     this.$store.commit("RESET");
        //   });
        })
        .catch(() => {
          // An error happened.
        });
    },
    reset() {
      this.$store.commit("RESET");
      this.$router.push("/");
    },
  },
};
</script>

<style>
</style>