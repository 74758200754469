import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import router from '../router'
import dayjs from 'dayjs'
import device from "../utils/device"
import getTestSummary from "../utils/getTestSummary"
import getSummary from "../utils/calcSummary"
import saveDoc from "../utils/saveDoc"
import getConnection from '../utils/getConnection'

// import getSetTest from '../utils/getSetTest'
import {  v4 as uuidv4 } from 'uuid'
// import isSupported from '../utils/isSupported'
// import checkWarnings from '../utils/checkWarnings'

const storageKey = 'VIPdesk-Verify'

const vuexPersist = new VuexPersistence({
  key: storageKey,
  reducer(state) {
    return {
      session: state.session,
      sessionId: state.sessionId,
      currentStep: state.currentStep,
      pass: state.pass,
      complete: state.complete,
      counts: state.counts,
      results: state.results,
      date: state.date,
      startTime: state.startTime,
      endTime: state.endTime,
      duration: state.duration,
    }
  }
})

const Session = function (start) {
  this.id = start ? uuidv4() : null
  this.ticket = null
  this.currentStep = null
  this.connection = null
  this.date = start ? dayjs().format('YYYY-MM-DD') : null
  this.startTime = Date.now()
  this.endTime = Date.now()
  this.duration = 0
  this.accepted = false
  this.mobile = null
  this.error = null
  this.results = {
    device,
    network: null,
    computer: null,
    devices: null,
    displays: null,
    internet: null,
    mobile: null,
  }
  this.counts = {
    network: 0,
    computer: 0,
    devices: 0,
    displays: 0,
    internet: 0,
    mobile: 0,
  }
  getConnection()
  return this
}

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    online: true,
    sessionId: null,
    session: new Session(),
    currentStep: null,
    warnings: [],
    user: null,
    config: {
      passFail: {
        location: {
          countries: {
            requirement: ['US', 'CA'],
            failMessage: 'Unfortunately, we are currently not hiring in your country.',
            help: null
          },
          states: {
            requirement: [
              "CO",
              "FL",
              "GA",
              "IL",
              "IN",
              "MD",
              "NV",
              "NM",
              "NJ",
              "NC",
              "OH",
              "TN",
              "TX",
              "UT",
              "VA",
              "WI",
              "NY",
              "ON",
            ],
            failMessage: 'Unfortunately, we are currently not hiring in your state/provence.',
            help: null
          }
        },
        displays: {
          count: {
            requirement: 2,
            failMessage: 'Unfortunately, we require at least 2 displays for this position.',
            help: null
          },
          resolution: {
            requirement: [1920, 1080],
            failMessage: 'Unfortunately, we are require at least one monitor with a resoltion of at least 1920 x 1080 px.',
            help: null
          }
        },
        os: {
          windows: {
            value: 10,
            help: null
          },
          macOs: {
            value: 10,
            help: null
          }
        },
        internet: {
          download: {
            requirement: 25,
            help: null
          },
          upload: {
            requirement: 25,
            help: null
          },

          jitter: {
            requirement: 25,
            help: null
          },
          latency: {
            requirement: 25,
            help: null
          },
        },
        computer: {
          cpu: {
            requirement: 2,
            help: null
          },
          cores: {
            requirement: 2,
            help: null
          }
        },
        devices: {
          webcam: {
            requirement: true,
            help: null
          },
          headset: {
            requirement: true,
            help: null
          }
        }
      }
    },
    guid: null,
    date: null,
    startTime: null,
    endTime: null,
    duration: 0,
    counts: {
      location: 0,
      network: 0,
      computer: 0,
      internet: 0,
      devices: 0,
      displays: 0,
    },
    ip: null,
    isp: {
      ips: [],
      detail: null,
      details: [],
      count: 0
    },//DELETE
    locations: {
      cities: [],
      details: [],
      count: 0
    },//DELETE
    history: [],//DELETE
    results: {
      device,
      network: null,
      location: null,
      computer: null,
      internet: null,
      devices: null,
      displays: null,
    }
  },
  getters: {
    sessionId: (state) => {
      return state?.session?.id
    },
    testSummary: getTestSummary,
    summary: (state) => {
      return getSummary(state)
    },
    saveListener:(state, getters) => {
      let pass = getters.testSummary.pass
      let complete = getters.testSummary.complete
      let ticket = getters.testSummary.ticket
      if (complete === 1 && ticket){
        saveDoc(getters.testSummary.id, getters.testSummary, 'testResults')
      }
      return complete === 1  ? `${complete}-${pass}-${ticket}` : null 
    },
    saveDoc: (state, getters) => {
      if (!state.user) {
        return {}
      }
      return {
        id: state.id,
        uid: state.user.uid,
        guid: state.guid,
        date: state.date,
        startTime: state.startTime,
        endTime: state.endTime,
        duration: state.duration,
        counts: state.counts,
        step: state.step,
        ip: state.ip,
        isp: state.isp,
        locations: state.locations,
        summary: getters.summary,
        pass: getters.summary.pass,
        complete: getters.summary.complete,
        results: state.results,
        user: state.user ? {
          email: state.user.email,
          uid: state.user.uid,
          name: state.user.displayName,
          providerData: state.user.providerData.map(item => item.providerId).join(',')
        } : null
      }
    }
  },
  mutations: {
    SET_CONNECTION(state, payload) {
      Vue.set(state.session, 'connection', payload)
    },
    SET_ERROR(state, payload) {
      Vue.set(state.session, 'error', payload)
    },
    RESTORE_MUTATION: vuexPersist.RESTORE_MUTATION,
    SET_STEP(state, payload) {
      state.session.currentStep = payload
      state.session = { ...state.session, ...{ currentStep: payload } }
      // Vue.set(state.session, 'currentStep', payload)
    },
    // SET_HISTORY(state, payload) {
    //   state.history = payload
    //   if (state.history[0] && !state.history[0].summary.complete) {
    //     // this.commit('RESUME', state.history[0])
    //   } else if (payload && payload.length) {
    //     if (isSupported.overall && router.currentRoute.name === 'Test' && router.currentRoute.params.step !== 'history') {
    //       router.push({ name: 'Test', params: { step: 'history' } })
    //     }
    //   }
    // },
    UPDATE_ISP(state, payload) {
      if (state.ip) {
        if (state.ip === payload.ip) {
          return
        } else {
          state.isp.ips.push(state.ip)
          state.isp.details.push(state.isp.details)
        }
      }
      state.ip = payload.ip
      state.isp.detail = payload.isp
      state.isp.count = 1 + state.isp.ips.length
    },
    // RESUME(state, payload) {
    //   let data = { ...payload }
    //   let summary = payload.summary
    //   delete data.user
    //   delete data.summary
    //   let keys = Object.keys(data)
    //   keys.forEach(key => {
    //     if (typeof state[key] == undefined) {
    //       delete data[key]
    //     } else {
    //       if (typeof data[key] != 'object') {
    //         Vue.set(state, key, data[key])
    //       } else {
    //         Vue.set(state, key, { ...state[key], ...data[key] })
    //       }
    //     }
    //   })
    //   if (!summary.complete && state.step != router.currentRoute.params.step && isSupported.overall) {
    //     router.push({ name: 'Test', params: { step: state.step } })
    //   }
    //   // else if('results' != router.currentRoute.params.step) {
    //   //   router.push({ name: 'Test', params: { step: 'results'} })
    //   // }
    // },
    RESET(state) {
      if (window.localStorage){
        window.localStorage.removeItem(storageKey)
      }
      state.session = new Session(true)

      // console.log('RESET', payload)
      // state = {
      //   ...state,
      //   session: new Session(payload)
      // }
      // console.log(state.session.id)
      // state.guid = uuidv4()
      // state.date = dayjs().format('YYYY-MM-DD')
      // state.id = `${state.date}-${state.user.email}-${state.guid}`
      // state.startTime = Date.now()
      // state.endTime = Date.now()
      // state.duration = 0
      // state.step = null
      // state.counts = {
      //   location: 0,
      //   computer: 0,
      //   internet: 0,
      //   devices: 0,
      //   displays: 0,
      // }
      // state.results = {
      //   device,
      //   location: null,
      //   computer: null,
      //   internet: null,
      //   devices: null,
      //   displays: null,
      // }
    },
    SET_USER(state, payload) {
      state.user = payload
    },
    SET_MOBILE(state, payload) {
      if (!payload) {
        let results = { ...state.session.results, ...{ mobile: null } }
        state.session = { ...state.session, ...{ results }, ...{ mobile: null } }
        return
      }
      state.session.counts.mobile++
      let mobiles = payload.sort((a, b) => a.timestamp - b.timestamp)
      let mobile = mobiles[0]
      let results = { ...state.session.results, ...{ mobile: mobiles } }
      state.session = { ...state.session, ...{ results }, ...{ mobile } }
    },
    // UPDATE_LOCATION(state, payload) {
    //   if (state.results.location) {
    //     let city = `${state.results.location.city}, ${state.results.location.st}`
    //     if (state.locations.cities.indexOf(city) < 0) {
    //       state.counts.location++
    //       state.locations.cities.push(city)
    //       state.locations.count++
    //       state.locations.details.push(state.results.location)
    //     }
    //   }
    //   Vue.set(state.results, 'location', payload)
    //   let city = `${payload.city}, ${payload.st}`
    //   if (state.locations.cities.indexOf(city) < 0) {
    //     state.locations.cities.push(city)
    //     state.counts.location++
    //     state.locations.count++
    //     state.locations.details.push(state.results.location)
    //   }
    //   Vue.set(state.results, 'location', payload)
    // },
    // UPDATE_RESULTS(state, { payload, getters }) {
    ACCEPT(state, payload) {
      window.localStorage.removeItem(storageKey)
      state.session = new Session(true)
      Vue.set(state.session, 'accepted', payload)
    },
    SET_TICKET(state, payload){
      Vue.set(state.session, 'ticket', payload)
    },
    UPDATE_RESULTS(state, payload) {
      //TODO  SET ID PER UPDATE AND SAVE IN SESSION

      if (!state.session.id) {
        state.session = new Session(true)
      }
      // state.step = router.currentRoute.params.step || null
      // state.endTime = Date.now()
      // state.duration = (state.endTime - state.startTime) / 1000
      // state.counts[payload.key] = state.counts[payload.key] + 1
      state.session.step = router.currentRoute.params.step || null
      state.session.endTime = Date.now()
      Vue.set(state.session, 'endTime', Date.now())
      Vue.set(state.session, 'duration', (state.session.endTime - state.session.startTime) / 1000)
      if (state.session.counts[payload.key] !== undefined) {
        state.session.counts[payload.key] = state.session.counts[payload.key] + 1
      }
      // let warning = checkWarnings(state, payload)
      // console.log({ warning })

      //      Vue.set(state.results, payload.key, payload.data)
      Vue.set(state.session.results, payload.key, payload.data)
      let results = { ...state.session.results, ...{ [payload.key]: payload.data } }
      if (payload.data) {
        let doc = { data: payload.data, ...{ sessionId: state.session.id, uid: state.user.uid,email: state.user.email } }
        let id
        switch (payload.key) {
          case 'internet':
          case 'network':
            id = null
            break
          default:
            id = state.session.id
        }
        saveDoc(id, doc, payload.key)
      }

      // console.log('====================', { results })
      // console.log(state.session.id)
      state.session = { ...state.session, ...{ results } }
      // console.log(state.session.id)
      // getSetTest(state.id, getters.saveDoc)
      // if (payload.key === 'internet') {
      //   let speedtest = state.results.internet
      //   speedtest.testId = state.id
      //   speedtest.count = state.counts[payload.key]
      //   getSetTest(speedtest.id, speedtest, 'speedtest')
      // }
    }
  },
  actions: {
    updateResults({ commit, state, getters }, payload) {
      if (payload) {
        if (!state.session.id) {
          commit('RESET', true)
        }
        if (payload.key) {
          commit('UPDATE_RESULTS', payload)
          if (payload.data) {
            saveDoc(getters.testSummary.id, getters.testSummary, 'testResults')
          }
          // commit('UPDATE_RESULTS', { payload, getters })
          // zendesk(state.user, payload)
        }
      } 
      // else {
      //   console.log('NO PAYLOAD')
      // }
    }
  },
  modules: {
  },
  plugins: [vuexPersist.plugin]

})

// store.restored.then(()=>{
//   console.log('RESTOOOOOORED')
// })


export default store