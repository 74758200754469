import firebase from "./firebase"
require("firebase/firestore")
const db = firebase.firestore()
import store from '../store'

const getDoc = (id, collectionName, callback) => {
    return new Promise((resolve) => {
        let collection = db.collection(collectionName)
        if (id) {
            collection = collection.doc(id)
        }
        if(!store.state.user){
            if (callback){
                callback([])
            }
            return resolve([])
        }
        collection.where('uid','==',store.state.user.uid).onSnapshot((querySnapshot) => {
            let results = []
            querySnapshot.forEach((doc) => {
                results.push(doc.data())
            })
            if (callback){
                callback(results)
            }
            return resolve(results)
        })
    })
}

export default getDoc
