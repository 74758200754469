import { getPreciseDistance } from 'geolib'

import getLocation from './getLocation'
import getISP from './getISP'

// const changeLocationThreshold = 0.05

async function getNetwork() {
    // async function getNetwork(user, previousLocation) {
        let location = await getLocation(true)
    if (!location) {
        // console.log(location)
        location = await getLocation()
        if (!location || location.position.error) {
            return
        }
    }
    let isp = await getISP()
    let ispDistance = -1
    if (isp && isp.ispLatitude) {
        ispDistance = isp.ispLatitude ? +((getPreciseDistance(location.position, { latitude: isp.ispLatitude, longitude: isp.ispLongitude }) / 1000).toFixed(3)) : -1
        // ispDistance.mi = ispDistance.km < 0 ? -1 : +((ispDistance.km * 0.621371).toFixed(3))
    }

    // let locationChange = null
    // if (previousLocation) {
    //     locationChange = +((getPreciseDistance(location.position, previousLocation.position) / 1000).toFixed(3))
    // }
    let result = {
        // id: user ? `${isp.ip}-${user.email}` : isp.ip,
        // uid: user ? user.uid || 'unknown' : 'unknown',
        isp,
        location,
        ispDistance,
        // locationChange,
        // locationChangeTooMuch: locationChange ? locationChange > changeLocationThreshold : false
    }
    // console.log({ result })
    return result

}

export default getNetwork