<template>
  <div class="home">
    <v-card>
       <v-toolbar
      color="primary"
      dark
    >
      <v-toolbar-title>Welcome to Verify</v-toolbar-title>
    </v-toolbar>
      <v-card-text>
        <p>
          Hello and welcome! We are excited that you are interested in joining
          our team! This tool will perform a high level check on your system to
          see if you meet our requirements.
        </p>

        <p>
          Please note, this site must be run from your home office on your home
          office computer. If that is not the case, please return this site once
          those conditions are true.
        </p>

        <p>
          To begin the verification process, click
          <v-btn :to="user ? 'test' : 'login'" color="secondary" small>HERE</v-btn>.
        </p>
        <p class="text-center pt-8">
          <img width="300" src="@/assets/vipdesk.png"/>
        </p>
       
      </v-card-text>
    </v-card>
    <!-- <CheckComputer @complete="completeComputerCheck"/>
    <CheckLocation @complete="completeLocationCheck"/>
    <CheckDevices  @complete="completeDevicesCheck"/>
    <CheckInternet @complete="completeInternetCheck"/>
    <CheckDisplays @complete="completeDisplaysCheck"/> -->
  </div>
</template>

<script>
import { mapState } from 'vuex';
// @ is an alias to /src
// import CheckDisplays from '@/components/CheckDisplays'
// import CheckLocation from '@/components/CheckLocation'
// import CheckDevices from '@/components/CheckDevices'
// import CheckInternet from '@/components/CheckInternet'
// import CheckComputer from '@/components/CheckComputer'

export default {
  name: "Home",
  components: {
    // CheckDisplays,
    // CheckLocation,
    // CheckDevices,
    // CheckInternet,
    // CheckComputer
  },
  computed:{
    ...mapState(['user'])
  },
  methods: {
    test() {
      // console.log({ val });
    },
    // completeDevicesCheck(data){
    //   this.$store.commit('UPDATE_RESULTS', {key:'devices',data})
    // },
    // completeInternetCheck(data){
    //   this.$store.commit('UPDATE_RESULTS', {key:'internet',data})
    // },
    // completeDisplaysCheck(data){
    //   this.$store.commit('UPDATE_RESULTS', {key:'displays',data})
    // },
    // completeLocationCheck(data){
    //   this.$store.commit('UPDATE_RESULTS', {key:'location',data})
    // },
    // completeComputerCheck(data){
    //   this.$store.commit('UPDATE_RESULTS', {key:'computer',data})
    // },
  },
};
</script>
