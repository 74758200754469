
const googleMapsKey = "AIzaSyBsS2eJF0KPfI4ol_3iF3HqGj4XDTTyZxg"
const basicLocationUrl = 'https://us-central1-vipdesk-apis.cloudfunctions.net/getLocation'
const geoCodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=`

let permission = null
let position = null
let callbacks = []

const getCurrentPosition = async (noPrompt) => {
    return new Promise((resolve) => {
        if (!navigator.geolocation) {
            return { error: 'Api not available' }
        }

        if (noPrompt){
            if (permission != 'granted'){
                return resolve(null)
            } 
        }
        navigator.geolocation.getCurrentPosition(
            (pos) => {
                let result = {
                    accuracy: pos.coords.accuracy,
                    latitude: pos.coords.latitude,
                    longitude: pos.coords.longitude,
                    latlong: `${pos.coords.latitude},${pos.coords.longitude}`,
                    error: null,
                }
                return resolve(result)
            },
            (error) => {
                return resolve({ error: error.message })
            }
        )
    })
}

const getBasicLocation = async () => {
    let resp = await fetch(basicLocationUrl).catch(err => {
        return {
            error: err
        }
    })
    let location = await resp.json()
    return {
        accuracy: -1,
        latitude: location.latitude,
        longitude: location.longitude,
        latlong: `${location.latitude},${location.longitude}`,
        error: null,
    }
}

const getPermissions = async () => {
    if (!navigator?.permissions?.query){
        return
    }
    let result = await navigator.permissions.query({
        name: "geolocation",
    })
    permission = result.state
    //denied, prompt, granted
    result.onchange = async () => {
        permission = result.state
        if (result.state === "granted") {
            await wait (100)
            if(callbacks.length){
                let location = getLocation()
                callbacks.forEach(cb=>cb(location))
            }
            
        }
    }
    return permission
}

const wait = async (time)=>{
    return  new Promise((resolve)=>{
        setTimeout(() => {
            return resolve()
        }, time)
    })
}

const getLocation = async (noPrompt) => {
    position = await getCurrentPosition(noPrompt)
    await wait (100)
    if (!position || permission === 'denied') {
        if (noPrompt){
            return null
        }
        position = {
            error: 'Position not provided',
            latlong: null,
            basic: true
        }
        position = await getBasicLocation()
    }
    let location = {
        permission,
        position,
        latlong: position.latlong || null,
        address: null,
        type: null,
        street: null,
        city: null,
        state: null,
        st: null,
        co: null,
        country: null,
        postal: null,
        error: null
    }
    if (!location.latlong) {
        return location
    }
    let resp = await fetch(
        `${geoCodeUrl}${location.latlong}&key=${googleMapsKey}`
    ).catch(err => {
        location.error = err
        return location
    })
    let data = await resp.json()

    location.address = data?.results[0]?.formatted_address || null
    location.type = data?.results[0]?.geometry?.location_type || null
    let address_components = data?.results[0]?.address_components || []
    address_components.forEach((row) => {
        if (row.types.indexOf("locality") > -1) {
            location.city = row.long_name
        } else if (row.types.indexOf("route") > -1) {
            location.street = row.long_name
        } else if (row.types.indexOf("administrative_area_level_1") > -1) {
            location.state = row.long_name
            location.st = row.short_name
        } else if (row.types.indexOf("country") > -1) {
            location.co = row.short_name
            location.country = row.long_name
        } else if (row.types.indexOf("postal_code") > -1) {
            location.postal = row.long_name
        }
    })
    return location
}

getPermissions()

export default getLocation

export const locationListener = (callback)=>{
    callbacks.push(callback)
}