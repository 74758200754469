import store from '../store'

let onlineState = null

window.addEventListener('offline', () => {
    getConnection()
})
window.addEventListener('online', () => {
    getConnection()
})

async function getConnection() {
    let online = await isOnline()
    if (online === onlineState){
        // console.log('NO CHANGE')
        return
    }
    onlineState = online
    let payload = {
        downlink: navigator?.connection?.downlink || null,
        rtt: navigator?.connection?.rtt || null,
        effectiveType: navigator?.connection?.effectiveType || null,
        saveData: navigator?.connection?.saveData || null,
        online
    }
    store.commit('SET_CONNECTION', payload)
    return payload
}

if (navigator?.connection) {
    navigator.connection.addEventListener('change', getConnection)
}


function getRandomString() {
    return Math.random().toString(36).substring(2, 15)
}

async function isOnline() {
    if (!window.navigator.onLine) return false
    const url = new URL(window.location.origin)
    url.searchParams.set('rand', getRandomString())
    try {
        const response = await fetch(
            url.toString(),
            { method: 'HEAD' },
        )
        return response.ok
    } catch {
        return false
    }
}

export default getConnection