let config = {
    requirements: {
        location: {
            US: ["AR",
                "AZ",
                "CO",
                "DE",
                "FL",
                "GA",
                "IL",
                "IN",
                "KY",
                "MD",
                "NE",
                "NV",
                "NM",
                "NJ",
                "NC",
                "OH",
                "SC",
                "TN",
                "TX",
                "UT",
                "VA",
                "WI",
                "NY"],
            CA: [
                "ON"
            ],
            help: ''
        },
        os: {
            Windows: ['10', '11'], // min version
            macOS: ['Mojave','High Sierra','Catalina','Big Sur', 'Monterey'],
            help:''
        },
        displays: {
            count: 2, // min version
            exempt: true,
            resolution: [1024, 768],
            help: ''
        },
        internet: {
            download: 25,
            upload: 10,
            jitter: 50,
            latency: 50,
            help: ''
        },
        computer: {
            architecture: [64],
            cpu: ['2,2','1.5,4'],
            ram: 4,
            excludeCPUs: '/(Celeron)/',
            help: ''
        },
        devices: {
            webcam: true,
            headset: true,
            badHeadsets: '(mpow)',
            exempt: true,
            help: ''
        }
    }
}

export default config