<template>
  <v-app id="app">
    <v-app-bar color="primary" dark dense app>
      <v-app-bar-title>
        <router-link to="/" tag="span">VIPdesk Verify</router-link>
      </v-app-bar-title>
      <v-spacer></v-spacer>
      <v-app-bar-title v-if="user">
        <UserMenu/>
      </v-app-bar-title>
    </v-app-bar>
    <v-main app>
      <v-container fill-height>
        <v-row align="center" justify="center">
          <v-col style="max-width: 850px">
            <router-view />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-app-bar
      color="primary"
      dark
      bottom
      dense
      app
      class="d-flex justify-center"
    >
      <v-app-bar-title>
        <span v-if="session.ticket && testSummary.complete == 1">
          Verify #{{ session.ticket }}
        </span>
        <!-- <div class="caption text-center">
          Interested in using Verify for you company?
          <span class="d-block d-sm-inline"></span>Contact Us at
          <span dark href="mailto:sales@VIPdeskConnect.com"
            >sales@VIPdeskConnect.com</span
          >
        </div> -->
      </v-app-bar-title>
    </v-app-bar>
  </v-app>
</template>
<script>
import firebase from "firebase";
import { mapGetters, mapState } from "vuex";
import icons from "./utils/icons";
import UserMenu from "./components/UserMenu";

let saveThrottle;
let saveVal
export default {
  watch: {
    async saveListener(val) {
      if (val) {
        console.log("SAVE", val, saveVal);
        if (saveVal !== val){
          saveVal = val
          setTimeout(()=>{
          this.createTicketFn();
          },100)
        }
      }
    },
  },
  components:{
    UserMenu
  },
  computed: {
    ...mapState(["user", "session"]),
    ...mapGetters(["testSummary", "saveListener"]),
    icon() {
      return icons["google.com"];
    },
  },
  methods: {
    createTicketFn() {
      clearTimeout(saveThrottle);
      saveThrottle = setTimeout(async () => {
        let createTicket = firebase.functions().httpsCallable("createTicket");
        let resp = await createTicket(this.testSummary).catch(err=>{
          console.log(err)
          this.$store.commit("SET_ERROR", JSON.stringify(err));
        })
        this.$store.commit("SET_TICKET", resp?.data?.ticket?.id || 8675309);
      }, 500);
    },
  },
};
</script>
<style>
</style>
