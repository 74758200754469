
let ipDataUrl = 'https://api.ipdata.co/?api-key=60139161410e95d3c8e7adcdc906fbf9eb7603cc543a67d1a5f7b13a'
// let ipDataUrl = 'https://api.ipify.org?format=json'

let services = [
    { name: 'ipregistry', url: 'https://api.ipregistry.co/?key=glhcdbdegiinvu', limit:'first 100000 free - month?' },
    { name: 'abstractapi', url: 'https://ipgeolocation.abstractapi.com/v1/?api_key=8cc1816d38d9484796419c705662e9be', limit: '20,000 requests/month'},
    { name: 'extreme-ip', url: 'https://extreme-ip-lookup.com/json/',limit: '10,000 requests per month' },
    { name: 'ipify', url: 'https://api.ipify.org?format=json' },
    // {name: 'ipstack',url: 'https://api.ipstack.com?access_key=670bc1e16f8189af05ba0962e9e68225', limit:'NO SSL'},
    // { name: 'ip-api', url: 'https://ip-api.com/json':limit:'NO SSL' },
]

let ipData = null

const getISP = async (i = 0) => {
    if (!ipData) {
        ipData = await getIP().catch(err=>{
            console.log('ERROR with Get IP',err)
        })
    }
    let service = services[i]
    if (!service) {
        return null
    }
    let resp = await fetch(service.url)
    if (!resp.ok) {
        return await getISP(i + 1).catch(err=>{
            console.log('ERROR with Get ISP',err)
        })
    }
    let data = await resp.json()

    // let result = {
    //     ip: data.query,
    //     isp: data
    // }
    // console.log(result)
    let ispData = processData(service, data)
    let warn = null
    let threat = data.threat || ispData.security || null
    if (threat) {
        Object.keys(threat).forEach(key => {
            if (threat[key]) {
                warn = key
            }
        })
    }
    // ipData,
    // ispData
    let result = {
        source: service.name,
        warn,
        detailed: data && data.ip ? true : false,
        ...(ipData || {}),
        ...(ispData || {}),
        // ispData,
        // ipData
    }
    return result
}

const getIP = async () => {
    let resp = await fetch(ipDataUrl).catch(err=>{
        console.log('ERROR with getIP',err)
    })
    if (!resp.ok) {
        return null
    }
    let data = await resp.json()
    return data
}

const processData = (service, data) => {
    switch (service.name) {
        case 'abstractapi':
            return {
                rawIspData: data,
                ip: data ? data.query || data.ip || null : null,
                ispName: data.connection.isp_name || null,
                ispAsn: data.connection.autonomous_system_number || null,
                ispDomain: null,
                ispCity: data.city || null,
                ispSt: data.region_iso_code || null,
                ispState: data.region || null,
                ispCo:  data.country_code || null,
                ispCountry: data.country || null,
                ispLatitude: data.latitude || null,
                ispLongitude: data.longitude || null,
                ispTimezone: data.timezone.name || null,
                ispType: data.connection.connection_type || null
            }
        case 'ipregistry':
            return {
                rawIspData: data,
                ispName: data.connection.organization || null,
                ispAsn: data.connection.asn || null,
                ispDomain: data.connection.domain || null,
                ispCity: data.location.city || null,
                ispSt: (data.location.region.code || '').split('-')[1] || data.location.region.code || null,
                ispState: data.location.region.name || null,
                ispCo: data.location.country.code || null,
                ispCountry: data.location.country.name || null,
                ip: data.ip || null,
                ispLatitude: data.location.latitude || null,
                ispLongitude: data.location.longitude || null,
                ispTimezone: data.time_zone.id || null,
                ispType: data.connection.type || null,
            }
        case 'extreme-ip':
            return {
                rawIspData: data,
                ispName: data.isp,
                ispAsn: null,
                ispDomain: null,
                ispCity: data.city,
                ispSt: (data.region || '').substring(0, 2).toUpperCase(),
                ispState: data.region,
                ispCo: data.countryCode,
                ispCountry: data.country,
                ip: data.query,
                ispLatitude: +data.lat,
                ispLongitude: +data.lon,
                ispTimezone: null,
                ispType: data.ipType || null,
            }
        default:
            return {
                rawIspData: data,
                ip: data ? data.query || data.ip || null : null,
                ispName: null,
                ispAsn: null,
                ispDomain: null,
                ispCity: null,
                ispSt: null,
                ispState: null,
                ispCo: null,
                ispCountry: null,
                ispLatitude: null,
                ispLongitude: null,
                ispTimezone: null,
                ispType: data.ipType || null,

            }
    }
}

export default getISP