import { fetch as fetchPolyfill } from 'whatwg-fetch'
window.fetch = window.fetch || fetchPolyfill
import './utils/sentry'
import Vue from 'vue'
import dayjs from 'dayjs'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import firebase from './utils/firebase'
// import speak from './utils/speak'
import getNetwork from './utils/getNetwork'
import device from './utils/device'
import getDoc from './utils/getDoc'
import './assets/main.css'
// import {getTests} from './utils/getSetTest';
// import getISP from './utils/getISP';
// let count = 0;
// firebase.auth().onAuthStateChanged((user) => {
//   count++;
//   this.user = user;
//   if (!user) {
//     this.init();
//   } else if (user && !user.providerData.length) {
//     return this.signOut();
//   }
//   if (user && count === 1) {
//     this.returningVisitor = true;
//     //check for docs instead
//   }
//   this.$store.commit("SET_USER", user);
//   if (user) {
//       console.log('NAV',user,count)
//      this.$router.replace({name:'Test',params:{step:'intro'}});
//   }
// });

function saveMobile(results) {
  if (results && results.length) {
    store.commit('SET_MOBILE', results)
    // store.dispatch('updateResults', {key:'mobile', data: results[0].name})
  } else {
    store.commit('SET_MOBILE', null)
  }
}

firebase.auth().onAuthStateChanged(async (user) => {
  if (user) {
    // User is signed in.
    let isAnonymous = user.isAnonymous
    if (isAnonymous) {
      firebase.auth().signOut().then(() => {
        // Sign-out successful.
      }).catch(() => {
        // An error happened.
      })
    }
    else {
      store.commit('SET_USER', user)
      if (device.platform.type !== 'desktop') {
        // router.replace({name:'Mobile'});

      } else {
        if ( window.zE){
          window.zE('webWidget', 'show');
        }
        let results = await getDoc(null, 'mobileDevice', (results) => {
          saveMobile(results)
        })
        saveMobile(results)
      }
      if (window.zE) {
        console.log(user)
        window.zE('webWidget', 'identify', {
          name: user.displayName,
          email: user.email,
        })

        window.zE('webWidget', 'prefill', {
          name: {
            value: user.displayName,
            readOnly: true // optional
          },
          email: {
            value: user.email,
            readOnly: true // optional
          },
        });
        

      }
      // getTests(user,isp)
      // let isp = await getISP()
      // store.commit('UPDATE_ISP', isp)
      // else if (store.state.session.currentStep){
      //   router.push(store.state.session.currentStep);
      // }
    }
    // ...
  } else {
    store.commit('SET_USER', null)
    store.commit('RESET')
    if ( window.zE){
      window.zE('webWidget', 'hide');
    }

    // User is signed out.
    // ...
  }
  // ...


  Vue.filter('formatTimestamp', function (value, pattern) {
    if (!value) return value
    return dayjs(value).format(pattern)
  })

  // Vue.directive('speak', {
  //   // When the bound element is inserted into the DOM...
  //   inserted(el) {
  //     // Focus the element
  //     let text = el.innerText.replace(/\n/gm, ' ')
  //     speak(text)
  //   },
  //   // componentUpdated(el) {
  //   //   // Focus the element
  //   //   let text = el.innerText.replace(/\n/gm,' ')
  //   //   console.log({text}, 'componentUpdated')
  //   //   speak(text)
  //   // },
  //   // update(el) {
  //   //   // Focus the element
  //   //   let text = el.innerText.replace(/\n/gm,' ')
  //   //   console.log({text}, 'update')

  //   //   speak(text)
  //   // },
  //   unbind() {
  //     speak(null)
  //   }
  // })

  Vue.config.productionTip = false
  new Vue({
    vuetify,
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
  if (store.state.results.network) {
    let network = await getNetwork()
    store.dispatch('updateResults', { key: 'network', data: network })
  }
})

