import config from './requirements'
import device from './device'

const checkCPU = (value, config, summary) => {
    let result = config.exempt ? 'exempt' : 'fail'
    config.forEach(cpu => {
        let parts = cpu.split(',')
        let speed = +(parts[0])
        let cores = +(parts[1])
        if (+value.cpuSpeed >= speed && +value.cpuCores >= cores) {
            result = 'pass'
        }
    })
    summary.results.cpu = result
    return result
}

const checkDisplays = (value, config, summary) => {
    let result = config.exempt ? 'exempt' : 'fail'
    let count = Object.keys(value).length
    if (count >= config.count) {
        result = 'pass'
    }
    summary.results.displays = result
    return result
}

const checkResolution = (value, config, summary) => {
    let result = 'fail'
    let displays = Object.keys(value).map(key => value[key])
    let resoltions = []
    displays.forEach(display => {
        resoltions.push(`${display.width} X ${display.height}`)
        if (display.width >= config.resolution[0] && display.height >= config.resolution[1]) {
            result = 'pass'
        }
    })
    summary.resolution = resoltions.join(', ')
    summary.results.resolution = result
    return result
}

const checkRAM = (value, config, summary) => {
    let result = config.exempt ? 'exempt' : 'fail'
    if (value >= config) {
        result = 'pass'
    }
    summary.results.ram = result
    return result
}

const checkOS = (value, config, summary) => {
    let result = config.exempt ? 'exempt' : 'fail'
    // let version = value.osVersion.toString().split('.')[0]
    // let subVersion = value.osVersion.toString().split('.')[1] || 0
    // if (subVersion){
    //     version = +`${version}.${subVersion}`
    // }
    if (config[value.name]) {
        if (config[value.name].indexOf(value.versionName) > -1) {
            result = 'pass'
        }
    }
    summary.results.os = result
    return result
}

const checkDownload = (value, config, summary) => {
    // let result = config.exempt ? 'exempt' : 'fail'
    // if (value >= config){
    //     result = 'pass'
    // }
    summary.results.downloadSpeed = value === null ? 'missing' : value >= config ? 'pass' : 'fail'
    // summary.results.downloadSpeed = result
    return summary.results.downloadSpeed
}
const checkUpload = (value, config, summary) => {
    summary.results.uploadSpeed = value === null ? 'missing' : value >= config ? 'pass' : 'fail'
    return summary.results.uploadSpeed
}
const checkLatency = (value, config, summary) => {
    summary.results.latency = value === null ? 'missing' : value <= config ? 'pass' : 'fail'
    return summary.results.latency
}
const checkJitter = (value, config, summary) => {
    summary.results.jitter = value === null ? 'missing' : value <= config ? 'pass' : 'fail'
    return summary.results.jitter
}

const checkHeadset = (value, config, summary) => {
    let badHeadsets = new RegExp(config.badHeadsets, 'igm')
    let goodHeadsets = value.filter(headset => {
        return !((headset.brand||'').match(badHeadsets))
    })
    // let goodHeadsets = value
    if (goodHeadsets.length) {
        summary.results.headset = 'pass'
    } else if (config.exempt) {
        summary.results.headset = 'exempt'
    } else {
        summary.results.headset = 'fail'
    }
    console.log('RETURN',summary)
    return summary.results.headset
}

const checkWebcam = (value, config, summary) => {
    if (value.length) {
        summary.results.webcam = 'pass'
    } else if (config.exempt) {
        summary.results.webcam = 'exempt'
    } else {
        summary.results.webcam = 'fail'
    }
    return summary.results.webcam
}

const checkArchitecture = (value, config, summary) => {
    if (!value) {
        summary.results.architecture = 'missing'
        return
    }
    if (config.indexOf(value) > -1) {
        summary.results.architecture = 'pass'
    } else {
        summary.results.architecture = 'fail'
    }
    return summary.results.architecture
}

const checkLocation = (value, config, summary) => {
    if (!value) {
        summary.results.location = 'missing'
        return
    } else if (value.permission !== 'granted') {
        summary.results.location = 'missing'
    }
    else if (config[value.co]) {
        if (config[value.co].indexOf(value.st) > -1) {
            summary.results.location = 'pass'
        } else {
            summary.results.location = 'fail'
        }
    } else {
        summary.results.location = 'fail'
    }
    return summary
}
const checkISPLocation = (value, config, summary) => {
    if (!value) {
        summary.results.location = 'missing'
        return
    } else if (value.permission !== 'granted') {
        summary.results.location = 'missing'
    }
    else if (config[value.ispCo]) {
        if (config[value.ispCo].indexOf(value.ispSt) > -1) {
            summary.results.location = 'pass'
        } else {
            summary.results.location = 'fail'
        }
    } else {
        summary.results.location = 'fail'
    }
    return summary
}

// const checkDisplayCount = (value,config)=>{
//     console.log('c',{config})
//     let displays = Object.keys(value || {}).length
//     let result = {
//         config,
//         pass: false,
//         value: displays,
//         title: 'Monitors',
//         display: `${displays} monitor${displays == 1 ? '' : 's'}`,
//         icon: 'mdi-monitor',
//         errors: []
//     }
//     if (result.value >= result.config.count.requirement){
//             result.pass = true
//     } 
//     return result
// }


// const checkDisplays = (value, config) => {
//     let displays = value ? Object.keys(value).map(key => value[key]) : []
//     let result = {
//         config,
//         pass: false,
//         exempt: true,
//         test: 'displays',
//         missing: !value || !displays,
//         value: value ? displays : null,
//         title: 'Monitors',
//         display: displays.map(display => `${display.width} x ${display.height}`).join(', '),
//         icon: 'mdi-monitor',
//         messages: []
//     }
//     if (!value) {
//         result.messages.push('Unfortunately, we were not able to detect your monitor(s).')
//         return result
//     }
//     if (displays && displays.length >= config.count) {
//         result.value.forEach(display => {
//             if (display.width >= config.resolution[0] && display.height >= config.resolution[1]) {
//                 result.pass = true
//             }
//         })
//         if (!result.pass) {
//             result.messages.push('Unfortunately your monitors do not meet our minimum requirements for resoltion')
//         }
//     } else {
//         result.count = displays.length
//         if (config.exempt && displays && displays.length>0){
//             result.messages.push(`We were able to detect ${displays.length} monitor${displays.length==1?'':'s'}. Please review the requirements for your position to ensure you meet requirements`)
//         } else {
//             result.messages.push('Unfortunately we require more monitors than what we were able to detect.' )
//         }
//     }
//     return result
// }

const getSummary = (state) => {
    let session = state.session || {}
    let { network, computer, internet, displays, devices } = session.results
    let devicesArray = devices ? Object.keys(devices).map(key => devices[key]) : []
    let headsets = devicesArray.filter(item => item.headset)
    let webcams = devicesArray.filter(item => item.webcam)

    // console.log({session, user:state?.user})

    let summary = {
        id: session?.id || null,
        version: '2.0.16',
        accepted: session?.accepted || false,
        ticket: session?.ticket || null,
        uid: state?.user?.uid || null,
        name: state?.user?.displayName || null,
        email: state?.user?.email || null,
        authProvider: state?.user?.providerData[0]?.providerId || null,
        date: session?.date || null,
        startTime: session?.startTime || null,
        endTime: session?.endTime || null,
        duration: session?.endTime ? (session.endTime - session.startTime) / 1000 : null,
        counts: session.counts,
        currentStep: session?.currentStep || null,
        results: {
            location: null,
            os: null,
            cpu: null,
            ram: null,
            architecture: null,
            downloadSpeed: null,
            uploadSpeed: null,
            latency: null,
            jitter: null,
            headset: null,
            webcam: null,
            displays: null,
            resolution: null,
            mobile: session.mobile ? 'pass' : 'missing',
        },
        mobile: session?.mobile ? `${session?.mobile?.osName} ${session?.mobile?.osVersionName}` : null,
        device: session?.results?.device || null,
        city: network?.location?.city || null,
        state: network?.location?.st || null,
        country: network?.location?.co || null,
        location: network?.location?.city ? `${network?.location?.city}, ${network?.location?.st}` : null,
        ispLocation: network?.isp?.ispCity ? `${network?.isp?.ispCity}, ${network?.isp?.ispSt}` : null,
        latitude: network?.location?.position?.latitude || null,
        longitude: network?.location?.position?.longitude || null,
        isp: network?.isp?.ispName || null,
        ispDistance: network?.ispDistance || null,
        ip: network?.isp?.ip || null,
        os: device?.os?.name || null,
        osName: computer?.osName || null,
        osVersion: computer?.osVersion || null,
        computerMake: computer?.manufacturer || null,
        computerModel: computer?.model || null,
        cpu: computer?.cpu || null,
        cpuCores: computer?.cpuCores || null,
        cpuSpeed: computer?.cpuSpeed || null,
        cpuBrand: computer?.cpuBrand || null,
        ram: computer?.ram || null,
        architecture: computer?.architecture || null,
        headset: headsets.map(item => item.name).join(', ') || null,
        headsets: headsets.length,
        webcam: webcams.map(item => item.name).join(', ') || null,
        webcams: webcams.length,
        displays: displays ? Object.keys(displays).length : null,
        resolution: null,
        downloadSpeed: internet?.download?.value || null,
        uploadSpeed: internet?.upload?.value || null,
        latency: internet?.latency?.value || null,
        jitter: internet?.jitter?.value || null,
        language: navigator.language,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        pass: null,
        complete: null,
    }


    if (!network) {
        summary.results.location = 'missing'
    } else {
        checkLocation(network?.location, config.requirements.location, summary)
        if (summary.results.location === 'fail' && network.location.position.accuracy > 100) {
            checkISPLocation(network?.isp, config.requirements.location, summary)
        }
    }
    if (!devices) {
        summary.results.webcam = 'missing'
        summary.results.headset = 'missing'
    } else {
        checkHeadset(headsets, config.requirements.devices, summary)
        checkWebcam(webcams, config.requirements.devices, summary)
    }

    if (!displays) {
        summary.results.displays = 'missing'
        summary.results.resolution = 'missing'
    } else {
        checkDisplays(displays, config.requirements.displays, summary)
        checkResolution(displays, config.requirements.displays, summary)
        // TODO
    }

    if (!internet) {
        summary.results = {
            ...summary.results,
            ...{
                downloadSpeed: 'missing',
                uploadSpeed: 'missing',
                latency: 'missing',
                jitter: 'missing',
            }
        }
    } else {
        checkDownload(summary.downloadSpeed, config?.requirements?.internet?.download, summary)
        checkUpload(summary.uploadSpeed, config?.requirements?.internet?.upload, summary)
        checkLatency(summary.latency, config?.requirements?.internet?.latency, summary)
        checkJitter(summary.jitter, config?.requirements?.internet?.jitter, summary)
    }

    if (!computer) {
        summary.results = {
            ...summary.results,
            ...{
                os: 'missing',
                cpu: 'missing',
                ram: 'missing',
                architecture: 'missing',
            }
        }
    } else {
        checkOS(device.os, config.requirements.os, summary)
        checkCPU(computer, config.requirements.computer.cpu, summary)
        checkRAM(computer.ram, config.requirements.computer.ram, summary)
        checkArchitecture(computer?.architecture, config.requirements.computer.architecture, summary)
    }

    let steps = Object.keys(summary.results).length + 1 //For Accepted
    let missing = Object.keys(summary.results).filter(key => summary.results[key] === 'missing').length
    if (!summary.accepted) {
        missing++
    }
    summary.complete = (steps - missing) / steps
    summary.pass = summary.complete === 1 && Object.keys(summary.results).filter(key => summary.results[key] === 'fail').length === 0

    // os: checkOS(results.computer, config.requirements.os),
    // cpu: checkCPU(results.computer, config.requirements.computer),
    // ram: checkRAM(results?.computer?.ram, config.requirements.computer),
    // architecture: checkArchitecture(results?.computer?.architecture, config.requirements.computer),
    // headset: checkHeadset(results?.devices, config.requirements.devices),
    // webcam: checkWebcam(results?.devices, config.requirements.devices),
    // displays: checkDisplays(results.displays, config.requirements.displays),
    // download: checkDownload(results?.internet?.download, config.requirements.internet),
    // upload: checkUpload(results?.internet?.upload, config.requirements.internet),
    // latency: checkLatency(results?.internet?.latency, config.requirements.internet),
    // jitter: checkJitter(results?.internet?.jitter, config.requirements.internet),
    // let missingCount = 0
    // Object.keys(summary.checks).map(key => {
    //     let check = summary.checks[key]
    //     if (check.missing) {
    //         summary.missing.push(key)
    //         if (!check.exempt) {
    //             missingCount++
    //             summary.pass = false
    //         }
    //     } else if (!check.pass && !check.exempt) {
    //         summary.pass = false
    //         summary.fails.push(key)
    //     }
    // })
    // if (!missingCount ){
    //     summary.complete = true
    // }
    return summary
}

export default getSummary